import React from "react";
import styled from "styled-components";
import typography from "../helpers/styles/typography";
import breakpoints from "../helpers/styles/breakpoints";
import BackgroundImage from "gatsby-background-image";
import { Paragraph } from "./Paragraph";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 5rem auto;
  margin-top: 50px;
  align-content: flex-end;
  flex-wrap: wrap;
  padding: 0 20px 0 20px;

  @media ${breakpoints.tablet} {
    flex-wrap: nowrap;
    margin-top: 120px;
  }
`;

const Title = styled.h2`
  font: ${typography.h2};
`;

const SubTitle = styled.p`
  font: ${typography.contentSmall};
  max-width: 500px;
  padding-bottom: 20px;
  padding-top: 10px;
`;

const TextContainer = styled.div`
  padding-horizontal: 10px;
  margin-top: 0px;

  @media ${breakpoints.tablet} {
    padding-horizontal: 50px;
    margin-top: 50px;
  }
`;

export const ImgSection = styled(BackgroundImage)`
  background-size: cover;
  width: 500px;
  height: 300px;
  margin: 0 auto;
  mask-image: url("/img/header_shape.svg");
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: 500px 500px;

  &:before {
    transform: rotate(5deg);
  }

  @media (min-width: 550px) {
    width: 475px;
    height: 350px;
    mask-size: 475px 350px;
    top: 23px;
    left: -85px;
  }
  @media ${breakpoints.tablet} {
    width: 565px;
    height: 400px;
    mask-position: top;
    mask-size: 565px 400px;
    left: -120px;
  }
`;

export const ImgSectionShade = styled.div`
  background: url("/img/hero_image_shade.svg") no-repeat center;
  background-size: cover;
  width: 700px;
  height: 350px;
  @media (min-width: 550px) {
    width: 508px;
    height: 500px;
  }
  @media ${breakpoints.tablet} {
    width: 580px;
    height: 450px;
  }
`;

export const HelpForHouseholds = ({ info = {} }) => {
  const { title, subTitle, sections = [] } = info;
  return (
    <Container>
      <ImgSectionShade>
        <ImgSection fluid={{ quality: 100, maxWidth: 1920 }}>
          <img src="../img/family-in-kitchen.webp" alt="family-in-kitchen" />
        </ImgSection>
      </ImgSectionShade>
      <TextContainer>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        {sections.map((banner, index) => {
          return <Paragraph {...banner} key={index} />;
        })}
      </TextContainer>
    </Container>
  );
};
