import styled, { css } from "styled-components";
import spacing from "../helpers/styles/spacing";
import breakpoints from "../helpers/styles/breakpoints";
import colors from "../helpers/styles/colors";
import { Link } from "gatsby";

export const ButtonLinkBlackWhite = styled(Link)`
  display: inline-block;
  padding: ${spacing.gutter10} ${spacing.gutter40};
  background-color: ${colors.white};
  color: ${colors.black};
  border-color: ${colors.black};
  border-width: 2px;
  border-style: solid;
  border-radius: 99px;
  font-family: unset;
  font-weight: bold;
  text-decoration: unset;
  text-align: center;
  margin: 1rem 0;

  width: unset;

  @media ${breakpoints.mobileXL} {
    width: fit-content;
  }
`;

export const ButtonLinkBlackYellow = styled(Link)`
  display: inline-block;
  padding: ${spacing.gutter10} ${spacing.gutter40};
  background-color: ${colors.black};
  color: ${colors.brandYellow};
  border-color: ${colors.black};
  border-width: 2px;
  border-style: solid;
  border-radius: 99px;
  font-family: unset;
  font-weight: bold;
  text-decoration: unset;
  text-align: center;
  margin: 1rem 0;

  width: unset;

  @media ${breakpoints.mobileXL} {
    width: fit-content;
  }
`;

export const ButtonABlackWhite = styled.a`
  display: inline-block;
  padding: ${spacing.gutter10} ${spacing.gutter40};
  background-color: ${colors.white};
  color: ${colors.black} !important;
  border-color: ${colors.black};
  border-width: 2px;
  border-style: solid;
  border-radius: 99px;
  font-family: unset !important;
  font-weight: bold !important;
  text-decoration: unset;
  text-align: center;
  margin: 1rem 0;
  cursor: pointer;
  width: unset;

  @media ${breakpoints.mobileXL} {
    width: fit-content;
  }
`;

export const ButtonBlueWhiteTextStyles = css`
  display: inline-block;
  padding: ${spacing.gutter10} ${spacing.gutter40};
  background-color: ${colors.white};
  color: ${colors.dustyBlue};
  border-color: ${colors.dustyBlue};
  border-width: 2px;
  border-style: solid;
  border-radius: 99px;
  font-family: unset;
  font-weight: bold;
  text-decoration: unset;
  text-align: center;
  margin: 1rem 0;
  cursor: pointer;
  width: unset;
  cursor: pointer;
  white-space: nowrap;
  @media ${breakpoints.mobileXL} {
    width: fit-content;
  }
`;
export const ButtonBlueWhite = styled.a`
  ${ButtonBlueWhiteTextStyles}
`;

export const ButtonWhiteBlueTextStyles = css`
  cursor: pointer;
  display: inline-block;
  padding: ${spacing.gutter10} ${spacing.gutter40};
  background-color: ${colors.dustyBlue};
  color: ${colors.white} !important;
  border-color: ${colors.dustyBlue};
  border-width: 2px;
  border-style: solid;
  border-radius: 99px;
  font-family: unset;
  font-weight: bold;
  text-decoration: unset;
  text-align: center;
  margin: 1rem 0;
  cursor: pointer;
  width: unset;

  @media ${breakpoints.mobileXL} {
    width: fit-content;
  }
`;

export const ButtonWhiteBlue = styled.a`
  ${ButtonWhiteBlueTextStyles}
`;

export const ButtonABlackYellow = styled.a`
  display: inline-block;
  padding: ${spacing.gutter10} ${spacing.gutter40};
  background-color: ${colors.black};
  color: ${colors.brandYellow} !important;
  border-color: ${colors.black};
  border-width: 2px;
  border-style: solid;
  border-radius: 99px;
  font-family: unset !important;
  font-weight: bold !important;
  text-decoration: unset;
  text-align: center;
  margin: 1rem 0;
  cursor: pointer;
  width: unset;

  @media ${breakpoints.mobileXL} {
    width: fit-content;
  }
`;
